import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

const HomePage = React.lazy(() => import("./HomePage/homePage"));
const Login = React.lazy(() => import("./HomePage/login"));
const SignUp = React.lazy(() => import("./HomePage/signUp"));

const Dashboard = React.lazy(() => import("./Dashboard/dashboard"));
const NftDashboard = React.lazy(() => import("./NFT-Dashboard/nftDashboard"));
const OurGames = React.lazy(() => import("./OurGames/ourGames"));
const ContactUs = React.lazy(() => import("./More/contactUs"));

const Careers = React.lazy(() => import("./Careers/careers"));
const Engineering = React.lazy(() => import("./Careers/engineering"));
const Art = React.lazy(() => import("./Careers/art"));
const Design = React.lazy(() => import("./Careers/design"));
const Operation = React.lazy(() => import("./Careers/operation"));
const Other = React.lazy(() => import("./Careers/other"));

const Apply = React.lazy(() => import("./Careers/apply"));
const ApplyForm = React.lazy(() => import("./Careers/applyForm"));
const Support = React.lazy(() => import("./Support/support"));
const BuyForm = React.lazy(() => import("./HomePage/buyForm"));
const PortSideBar = React.lazy(() => import("./PortFolio/portSideBar"));
const MarketPlace = React.lazy(() => import("./MarketPlace/marketPlace"));

const MarketProductDetail = React.lazy(() =>
  import("./MarketPlace/marketProductDetail")
);
const WalletModal = React.lazy(() => import("./HomePage/walletModal"));
const NoJob = React.lazy(() => import("./Careers/noJob"));
const TermsAndConditions = React.lazy(() =>
  import("./HomePage/termsAndConditions")
);
const PrivacyPolicy = React.lazy(() => import("./HomePage/privacyPolicy"));

const Routes = () => {
  return (
    <div>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <WalletModal />
        </Suspense>

        <Switch>
          <Route exact path="/">
            <Suspense fallback={<div>Loading...</div>}>
              <HomePage />
            </Suspense>
          </Route>
          <Route path="/signUp">
            <Suspense fallback={<div>Loading...</div>}>
              <SignUp />
            </Suspense>
          </Route>
          <Route path="/login">
            <Suspense fallback={<div>Loading...</div>}>
              <Login />
            </Suspense>
          </Route>
          <Route path="/ourgames">
            <Suspense fallback={<div>Loading...</div>}>
              <OurGames />
            </Suspense>
          </Route>
          <Route path="/contactus">
            <Suspense fallback={<div>Loading...</div>}>
              <ContactUs />
            </Suspense>
          </Route>
          <Route path="/careers">
            <Suspense fallback={<div>Loading...</div>}>
              <Careers />
            </Suspense>
          </Route>
          <Route path="/support">
            <Suspense fallback={<div>Loading...</div>}>
              <Support />
            </Suspense>
          </Route>
          <Route path="/apply">
            <Suspense fallback={<div>Loading...</div>}>
              <Apply />
            </Suspense>
          </Route>
          <Route path="/applyform">
            <Suspense fallback={<div>Loading...</div>}>
              <ApplyForm />
            </Suspense>
          </Route>
          <Route path="/engineering">
            <Suspense fallback={<div>Loading...</div>}>
              <Engineering />
            </Suspense>
          </Route>
          <Route path="/design">
            <Suspense fallback={<div>Loading...</div>}>
              <Design />
            </Suspense>
          </Route>
          <Route path="/art">
            <Suspense fallback={<div>Loading...</div>}>
              <Art />
            </Suspense>
          </Route>
          <Route path="/operation">
            <Suspense fallback={<div>Loading...</div>}>
              <Operation />
            </Suspense>
          </Route>
          <Route path="/other">
            <Suspense fallback={<div>Loading...</div>}>
              <Other />
            </Suspense>
          </Route>
          <Route path="/buySell">
            <Suspense fallback={<div>Loading...</div>}>
              <BuyForm />
            </Suspense>
          </Route>
          <Route path="/portfolio">
            <Suspense fallback={<div>Loading...</div>}>
              <PortSideBar />
            </Suspense>
          </Route>
          <Route path="/portfolio">
            <Suspense fallback={<div>Loading...</div>}>
              <PortSideBar />
            </Suspense>
          </Route>

          <Route path="/marketPlace">
            <Suspense fallback={<div>Loading...</div>}>
              <MarketPlace />
            </Suspense>
          </Route>
          <Route path="/market_product_detail">
            <Suspense fallback={<div>Loading...</div>}>
              <MarketProductDetail />
            </Suspense>
          </Route>
          <Route path="/no_job">
            <Suspense fallback={<div>Loading...</div>}>
              <NoJob />
            </Suspense>
          </Route>
          <Route path="/terms_conditions">
            <Suspense fallback={<div>Loading...</div>}>
              <TermsAndConditions />
            </Suspense>
          </Route>
          <Route path="/privacy_policy">
            <Suspense fallback={<div>Loading...</div>}>
              <PrivacyPolicy />
            </Suspense>
          </Route>
          {/* -----------------Private-------- */}
          <Route path="/dashboard">
            <Suspense fallback={<div>Loading...</div>}>
              <Dashboard />
            </Suspense>
          </Route>
          <Route path="/nftdashboard">
            <Suspense fallback={<div>Loading...</div>}>
              <NftDashboard />
            </Suspense>
          </Route>
          <Redirect to="/" />
        </Switch>
      </Router>
    </div>
  );
};

export default Routes;
